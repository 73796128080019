.leaflet-container {  
  display: flex;
  align-items: top;
  justify-content: center;
  width: 100vw;  height: 100vh; 
  z-index: 0;
}

.leaflet-div-icon {
  background: none;
  border: none;
}

@keyframes fade { 
  from { opacity: 0.5; } 
}

.animated-beer-icon {
  animation: bounce .3s ease infinite alternate;
}

@keyframes bounce {
  100% {
    top: -20px;
  }
}

.help-tool {
  background-color: #F4F4F4;
  padding: 6px;
  cursor: pointer;
}

/* .leaflet-bottom .help-control {
  max-height: 44px;
  cursor: pointer;
} */

section {
  height: 200px;
  width: 200px;
  border: 1px solid #2d2d2d;
  margin-bottom: 12px;
}

.box {
  color: #fff;
  background: #2d2d2d;
  padding: 12px;
}

/* centering with CSS Grid */
.column-center {
  display: grid;
  justify-content: center;
  gap: 4px;
  align-content: center;
  grid-auto-flow: column;
}

.item-center {
  display: grid;
  grid-auto-flow: column;
  gap: 4px;
  align-items: center;
  justify-items: center;
}

.leaflet-popup-content p {
  margin: 0;
}